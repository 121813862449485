<template>
    <v-content>
        <div class="container">
            <!--<v-breadcrumbs :items="breadcrumbItems" large></v-breadcrumbs>-->
            <div slot="table-actions">
                <!-- <div class="my-1">
                     <v-btn color="primary" @click.stop="addModal">Project Form</v-btn>
                 </div>-->
            </div>
            <vue-good-table :columns="columns"
                            :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                            :rows="rows"
                            :totalRows="projectData.totalRecords"
                            @on-column-filter="onColumnFilter"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'createdTS'">
                        <span>{{props.row.createdTS | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <v-icon @click.stop="editItem(props.row)" class="mr-2" small>edit</v-icon>
                        <v-icon @click="deleteItem(props.row)" small>delete</v-icon>
                        <v-chip @click="goToQuestion(props.row)"
                                class="ml-2"
                                color="green"
                                small
                                text-color="white">
                            Questions
                        </v-chip>
                    </span>
                </template>
            </vue-good-table>
            <!-- <v-dialog
                     v-model="snackbar.formModal"
                     transition="dialog-bottom-transition"
                     max-width="590"
                     persistent
             >
                 <v-card>
                     <ProjectForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"
                     ></ProjectForm>
                     <ProjectEditForm @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-if="snackbar.editFormComponent"
                                      :editItemIndex="projectData.editItemIndex"
                     ></ProjectEditForm>
                 </v-card>
             </v-dialog>-->
            <v-snackbar
                    :color="snackbar.color"
                    :timeout="snackbar.timeout"
                    top
                    v-model="snackbar.snackbar"
            >
                {{ snackbar.text }}
                <v-btn
                        @click="snackbar.snackbar = false"
                        dark
                        text
                >
                    Close
                </v-btn>
            </v-snackbar>
            <v-dialog max-width="500px" v-model="snackbar.dialogDelete">
                <deleteListData :deleteFormData="projectData.deleteFormData"
                                @formResponse="onResponseDelete"></deleteListData>
            </v-dialog>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";
    import {mapGetters} from 'vuex'
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "BranchProjectList",
        components: {
            'deleteListData': deleteListData
        },
        computed: {
            ...mapGetters([
                'getDashboardLink',
                'getUserData',
            ]),
            breadcrumbItems() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: '',
                        exact: true
                    },
                    {
                        text: this.$t('survey'),
                        disabled: true,
                    }
                ]
            }
        },
        data() {
            return {
                columns: [
                    {
                        label: 'project_name',
                        field: 'projectName',
                    },
                    {
                        label: 'created_date',
                        field: 'createdTS',
                    },
                    {label: 'actions', field: "actions"}
                ],
                rows: [],
                projectData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {}
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "projectID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            };
        },
        methods: {
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            goToQuestion: function ({projectID}) {
                this.$router.push({
                    name: 'SurveySystem',
                    params: {
                        "projectId": projectID,
                    }
                })
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.projectData.editItemIndex = item.projectID
            },

            deleteItem(item) {
                this.snackbar.dialogDelete = true;
                console.log(item)
                this.projectData.deleteFormData.url = "Survey/DeleteSurveyProjectsByIDAsync/" + item.projectID;
                this.loadItems();
            },

            onResponseDelete(responseObj) {
                this.snackbar.dialogDelete = false;
                this.snackbar.color = responseObj.color ? responseObj.color : 'red'
                this.snackbar.text = responseObj.message ? responseObj.message : 'Server Error'
                this.snackbar.snackbar = true
                this.loadItems();
            },

            formResponse(responseObj) {
                this.snackbar.color = responseObj.color ? responseObj.color : 'red'
                this.snackbar.text = responseObj.message ? responseObj.message : 'Server Error'
                this.snackbar.snackbar = true
                this.dialogueClose();
            },

            dialogueClose() {
                this.projectData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.loadItems()
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    "divisionID": parseInt(this.getUserData.DivisionID)
                };
                axios.post('Survey/GetSurveyProjectListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                        this.snackbar.breadcrumbItems[0].to = this.getDashboardLink;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
        },
        mounted() {
            this.loadItems()
        },

    }
</script>

<style scoped>

</style>
